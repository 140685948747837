<template>
  <div>
    <MallheaderVue
      :customstyle="{ background: '#FFFFFF' }"
      :titlename="'完善资料'"
    ></MallheaderVue>
    <div class="dataconent">
      <div class="inputbox">
        <van-field
          label-align="right"
          label-width="60"
          v-model="linkman"
          label="姓名"
          placeholder="请输入姓名"
        />
      </div>
      <div class="inputbox marginT16">
        <van-field
          label-align="right"
          label-width="60"
          v-model="mobile"
          label="电话"
          placeholder="请输入电话"
        />
      </div>
      <div class="inputbox marginT16">
        <van-field
          label-align="right"
          label-width="60"
          v-model="store_name"
          label="商家名称"
          placeholder="请输入商家名称"
        />
      </div>
      <!-- <div class="inputbox marginT16">
        <van-field
          label-align="right"
          label-width="60"
          v-model="desc"
          label="商家地址"
          placeholder="请输入商家地址"
        />
      </div> -->
      <div class="headlogo marginT16">
        <div>头像LOGO</div>
        <div class="uploadbox">
          <van-uploader
            v-model="fileList"
            :max-count="1"
            :max-size="5000 * 1024"
            :after-read="clickUpload"
          >
          </van-uploader>
        </div>
      </div>
      <div class="textareabox marginT16">
        <van-field
          rows="3"
          label-align="right"
          label-width="60"
          v-model="desc"
          label="商家描述"
          type="textarea"
          placeholder="请输入文字"
        />
      </div>
      <div class="btncell">
        <div class="btn" @click="clickAlert">修改</div>
      </div>
    </div>
  </div>
</template>

<script>
import MallheaderVue from "../../components/mallheader.vue";
import { uploadimg, getStoreInfo } from "@/request/shopapi";
import { editStore } from "@/request/storeapi";

import { Toast } from "vant";
export default {
  components: {
    MallheaderVue,
  },
  data() {
    return {
      linkman: "",
      desc: "",
      fileList: [],
      logo: "",
      store_name: "",
      mobile: "",
        address:'',
        userInfo:{},
    };
  },
  activated() {
      this.address = sessionStorage.getItem("address");
      this.userInfo = sessionStorage.getItem("user")
          ? JSON.parse(sessionStorage.getItem("user"))
          : {};
    this.requestStoreInfo();
  },
  methods: {
    requestStoreInfo() {
      getStoreInfo({ address: this.address }).then((res) => {
        this.linkman = res.data.linkman;
        this.desc = res.data.desc;
        this.logo = res.data.logo;
        this.store_name = res.data.store_name;
        this.mobile = res.data.mobile;
        this.fileList = [
          {
            url: this.logo,
          },
        ];
      });
    },
    requesteditStore() {
      const params = {
        store_name: this.store_name,
        mobile: this.mobile,
        linkman: this.linkman,
        logo: this.logo,
        desc: this.desc,
      };
      editStore(params).then((res) => {
        Toast(res.msg);
      });
    },
    clickAlert() {
      if (this.fileList.length === 0) {
        this.logo = "";
      }
      if (this.linkman.length === 0) {
        Toast("请输入姓名");
        return;
      }
      if (this.mobile.length === 0) {
        Toast("请输入手机号");
        return;
      }
      if (this.store_name.length === 0) {
        Toast("请输入店铺名称");
        return;
      }
      if (this.logo.length === 0) {
        Toast("请上传图片");
        return;
      }
      if (this.desc.length === 0) {
        Toast("请输入描述");
        return;
      }
      this.requesteditStore();
    },
    clickUpload(file) {
      file.status = "uploading";
      file.message = "上传中...";
      const fd = new FormData();
      fd.append("file", file.file);
      uploadimg(fd)
        .then((res) => {
          this.logo = res.data.http_url;
          file.status = "done";
        })
        .catch((err) => {
          console.log(err);
          file.status = "failed";
          file.message = "上传失败";
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.marginT16 {
  margin-top: 16px;
}
.dataconent {
  height: 100%;
  background: white;
  padding: 0 32px 20px 32px;
  .inputbox {
    width: 686px;
    height: 84px;
    border-radius: 16px;
    opacity: 1;
    border: 2px solid rgba(255, 223, 204, 1);
    background: rgba(250, 247, 245, 1);
    .verode {
      font-size: 24px;
      font-weight: 400;
      font-family: "PingFang SC";
      text-align: left;
      color: rgba(255, 114, 32, 1);
    }
    ::v-deep .van-cell {
      background-color: transparent;
      line-height: 20px;
    }
    ::v-deep .van-cell__title {
      font-weight: 700;
      font-family: "PingFang SC";
    }
  }
  .headlogo {
    color: rgba(0, 0, 0, 0.9);
    font-size: 24px;
    font-weight: 700;
    font-family: "PingFang SC";
    line-height: 36px;
    .uploadbox {
      margin-top: 8px;
    }
  }
  .textareabox {
    width: 686px;
    min-height: 156px;
    border-radius: 16px;
    opacity: 1;
    border: 2px solid rgba(255, 223, 204, 1);
    background: rgba(250, 247, 245, 1);
    ::v-deep .van-cell {
      background-color: transparent;
    }
    ::v-deep .van-cell__title {
      font-weight: 700;
      font-family: "PingFang SC";
    }
  }
  .btncell {
    display: flex;
    justify-content: center;
    margin-top: 200px;
    .btn {
      width: 478px;
      height: 72px;
      border-radius: 74px;
      opacity: 1;
      background: rgba(255, 114, 32, 1);
      color: rgba(238, 238, 238, 1);
      font-size: 28px;
      font-weight: 700;
      font-family: "PingFang SC";
      text-align: center;
      line-height: 72px;
    }
  }
}
</style>
